exports.components = {
  "component---src-pages-analytics-tsx": () => import("./../../../src/pages/analytics.tsx" /* webpackChunkName: "component---src-pages-analytics-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-help-account-deletion-tsx": () => import("./../../../src/pages/help/accountDeletion.tsx" /* webpackChunkName: "component---src-pages-help-account-deletion-tsx" */),
  "component---src-pages-indexold-tsx": () => import("./../../../src/pages/indexold.tsx" /* webpackChunkName: "component---src-pages-indexold-tsx" */),
  "component---src-pages-phone-auth-tsx": () => import("./../../../src/pages/PhoneAuth.tsx" /* webpackChunkName: "component---src-pages-phone-auth-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-tsx": () => import("./../../../src/pages/reset.tsx" /* webpackChunkName: "component---src-pages-reset-tsx" */),
  "component---src-pages-rss-feed-tsx": () => import("./../../../src/pages/rssFeed.tsx" /* webpackChunkName: "component---src-pages-rss-feed-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-watch-tsx": () => import("./../../../src/pages/watch.tsx" /* webpackChunkName: "component---src-pages-watch-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

