import React, { createContext, useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'

export const AuthContext = createContext({
  user: undefined,
  setUser: (user: any) => console.warn('no user provided'),
})

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<firebase.User>()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      }
    })

    return () => unsubscribe()
  }, [])
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
