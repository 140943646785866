// CreditsContext.tsx
import React, { createContext, useContext, useState } from "react";

export const CreditsContext = createContext({})

const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState<number>(0);

    const updateCredits = (newCredits: number) => {
      setCredits(newCredits);
    };

    return (
      <CreditsContext.Provider value={{ credits, updateCredits }}>
        {children}
      </CreditsContext.Provider>
    );
    
}

export default CreditsProvider