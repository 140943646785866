/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css'
import './src/styles/tailwind.css'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/database'
import React from 'react'
import AuthProvider from './src/context/auth'
import CreditsProvider from './src/context/credit'
import firebase from 'firebase/compat/app'
import { Notifications } from 'react-push-notification'

const firebaseConfig = {
  apiKey: process.env.GATSBY_apiKey,
  authDomain: process.env.GATSBY_authDomain,
  projectId: process.env.GATSBY_projectId,
  storageBucket: process.env.GATSBY_storageBucket,
  messagingSenderId: process.env.GATSBY_messagingSenderId,
  appId: process.env.GATSBY_appId,
  measurementId: process.env.GATSBY_measurementId,
  databaseURL: process.env.GATSBY_databaseURL,
}

firebase.initializeApp(firebaseConfig)

export const wrapRootElement = ({ element }) => (
  <>
    <Notifications />
    <AuthProvider>
      <CreditsProvider>{element}</CreditsProvider>
    </AuthProvider>
  </>
)
